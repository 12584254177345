import { MdArrowForwardIos } from 'react-icons/md'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

import { LinkCard } from './LinkCard'

type DrawerNaviItemProps = {
  link: string
  imgPath: string
  title: string
  isExternalLink?: boolean
}

export const DrawerNaviItem = ({
  link,
  imgPath,
  title,
  isExternalLink = false,
}: DrawerNaviItemProps) => {
  const handleClick = (e:any) => {
    e.preventDefault();
    window.location.href = link;
  };
  return (
    <Wrapper>
      <DrawerNaviItemStyled imgPath={imgPath} />
      {isExternalLink ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          className="drawer-link external-link"
        >
          {title}
        </a>
      ) : ( 
        <a href={link} onClick={handleClick} className="drawer-link focus-item">
          {title}
          <Arrow color="#3786C7" />
        </a>
      )}
    </Wrapper>
  )
}

const DrawerNaviItemStyled = styled(LinkCard)`
  width: 100%;
  height: 110px;


  @media screen and (max-width: 1920px) {
      height: 90px;
    }

  // @media screen and (max-width: 1920px) {
  //   height: 80px;
  // }


  img {
    object-fit: fill;
  }
`

const Arrow = styled(MdArrowForwardIos)`
  margin-left: 8px;
  margin-bottom: 2px;
`

const Wrapper = styled.div`
  position: relative;
  width: 190px;
  height: 152px;


  @media screen and (max-width: 1920px) {
    height: 110px;
  }
  // @media screen and (max-width: 1920px) {
  //   height: 90px;
  // }


  .drawer-link {
    white-space: nowrap;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 125%;
    height: 100%;
    display: flex;
    align-items: baseline;
    color: #fff;
    line-height: 24px;
    padding-top: 120px;

    @media screen and (max-width: 1920px) {
        padding-top: 90px;
    }

    // @media screen and (max-width: 1920px) {
    //   padding-top: 90px;
    // }

    letter-spacing: 1.6px;

    &:hover {
      color: ${theme.colors.hoverLightBlue};
    }
  }

  .external-link {
    &:after {
      content: '';
      background: url('/images/external-link.svg') no-repeat;
      width: 12px;
      height: 12px;
      margin-left: 8px;
      display: inline-block;
    }
  }

  &:hover ${DrawerNaviItemStyled} {
    opacity: 0.6;
  }
`
